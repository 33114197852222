import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FilterLayout, FilterFieldDeclaration } from "@app/admin/components/filter-layout";

@Component({
  selector: '[shipment-queue-filter-need-appointment]',
  templateUrl: './need-appointment.html',
  styleUrls: ['./style.scss']
})
export class ShipmentQueueFilterNeedAppointment implements OnInit, AfterViewInit {

  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.filter.ngHostAfterViewInit(this.getFilterFields())
  }

  // for filter layout
  @ViewChild('filter') filter: FilterLayout;
  @ViewChild('tplFilter_shipmentWarpId') tplFilter_shipmentWarpId: TemplateRef<any>;
  @ViewChild('tplFilter_customer') tplFilter_customer: TemplateRef<any>;
  @ViewChild('tplFilter_type_customer') tplFilter_type_customer: TemplateRef<any>;

  onFilterChange(key: string, value: any) {
    this.filter.onChangeWithWarpIdString({key, value});
  }

  getFilterFields(): FilterFieldDeclaration[] {
    return [
      {
        key: 'warpId',
        declaration: { label: 'WARP ID', placeHolder: '', notAcceptEmpty: true },
        templateInput: this.tplFilter_shipmentWarpId
      },
      {
        key: 'clientId',
        declaration: { label: 'Customer', placeHolder: '', notAcceptEmpty: true },
        templateInput: this.tplFilter_customer
      },
      {
        key: 'clientFilterType',
        declaration: { label: '', placeHolder: '', notAcceptEmpty: true, initialValue: 'include' },
        templateInput: this.tplFilter_type_customer
      }
    ]
  }
}
