import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BaseList } from "@app/admin/base/list";
import { NavigationEndData } from "@app/interfaces";
import { Const } from "@const/Const";
import { BizUtil } from "@services/biz";
import { MasterData } from "@services/master.data";
import { BookAppointment, FormDataBookAppointment, FormDataUpdateTimeWindows, ModalHelper, UpdateTimeWindows } from "@wearewarp/ng-antd";
import { StringULID } from "@wearewarp/types";
import { AddressUS } from "@wearewarp/types/data-model";
import { ShipmentQueueDeliveryInfo } from "@wearewarp/types/rest-api/admin";
import { ShipmentQueueShipment } from "./interface";
import { NzDrawerService } from "ng-zorro-antd/drawer";
import { DateUtil } from "@services/date-utils";

@Component({
  selector: '[shipment-queue-list-need-appointments]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class ShipmentQueueListNeedAppointments extends BaseList<ShipmentQueueShipment> {
  @Input() apiPath: string;
  @Input() isVisible: boolean = false;
  @Output() sumDataUpdated = new EventEmitter<any>();

  get hasAction(): boolean { return this.apiPath == 'need_review' || this.apiPath == 'need_legs' }
  get shouldShowFilter(): boolean { return this.apiPath == 'need_routing' }

  constructor(private modalHelper: ModalHelper, private nzDrawerService: NzDrawerService) {
    super();
    this.subscribeNavigationEnd();
  }

  getRouterLink(item: ShipmentQueueShipment) {
    if (!item.shipmentTransitType || item.shipmentTransitType == Const.ShipmentTransitType.none) {
      return [this.routeAdminOrderList, item.orderId];
    } else {
      return [this.routeAdminOrderList, item.parentId, 'tracking-items']
    }
  }

  isOrderful(item: ShipmentQueueShipment) {
    return item.order?.source === Const.thirdPartyOrderSource.orderful;
  }

  // Gọi trong hàm constructor
  protected subscribeNavigationEnd() {
    this.appComponent.subscribeNavigationEnd((e: NavigationEndData) => {
      if (this.isVisible) {
        this.onNavigationEnd(e.current.url);
      }
    });
    if (this.appComponent.getCurrentNavigationUrlData()) {
      // Đợi kết thúc hàm constructor rồi mới thực hiện
      setTimeout(() => this.onNavigationEnd(this.appComponent.createNavigationEndData().current.url), 0);
    }
  }

  protected getApiUrl(): string {
    return Const.APIURI_SHIPMENT_QUEUE(this.apiPath);
  }

  protected getDataDone(resp: any): void {
    this.processData(resp);
    super.getDataDone(resp);
  }

  private processData(resp) {
    let total: number = resp.data.total ?? 0;
    this.sumDataUpdated.emit({total});
    if (!resp.data.list_data) {
      return;
    }
    for (let item of resp.data.list_data) {
      this.processItem(item);
    }
  }

  private processItem(item: ShipmentQueueShipment) {
    const pickInfo = BizUtil.getPickInfo(item);
    const dropInfo = BizUtil.getDropInfo(item);
    item.pickStreet = this.getStreet(pickInfo.addr);
    item.pickCity = this.getCityState(pickInfo.addr);
    item.pickAddr = this.getAddressText(pickInfo.addr);
    item.pickDt = BizUtil.getDeliveryInfoTime(pickInfo, {appointmentFlag: '\n(Appointment Scheduled)'});
    item.dropCity = this.getCityState(dropInfo.addr);
    item.dropStreet = this.getStreet(dropInfo.addr);
    item.dropAddr = this.getAddressText(dropInfo.addr);
    item.dropDt = BizUtil.getDeliveryInfoTime(dropInfo, {appointmentFlag: '\n(Appointment Scheduled)'});
    const shouldWarnPickAppt = pickInfo.requiresAppointment == true && !pickInfo.appointmentInfo?.from;
    const shouldWarnDropAppt = dropInfo.requiresAppointment == true && !dropInfo.appointmentInfo?.from;
    const shouldWarnPickWindow = !shouldWarnPickAppt && !item.pickDt && !pickInfo.windows?.[0]?.from;
    const shouldWarnDropWindow = !shouldWarnDropAppt && !item.dropDt && !dropInfo.windows?.[0]?.from;
    item.shouldWarnAppt = shouldWarnPickAppt || shouldWarnDropAppt;
    item.warnPickAppt = shouldWarnPickAppt ? 'Requires Appointment' : '';
    item.warnDropAppt = shouldWarnDropAppt ? 'Requires Appointment' : '';
    item.warnPickWindow = shouldWarnPickWindow ? 'No Time Windows' : '';
    item.warnDropWindow = shouldWarnDropWindow ? 'No Time Windows' : '';
  }

  shouldWarnAppt(location){
    return location?.requiresAppointment == true && !location?.appointmentInfo?.from && !this.isAwaitingResponse(location) && !location?.skipAppointment
  }

  isApptScheduled(location){
    return location?.appointmentInfo?.from && !location?.skipAppointment
  }

  isWindowsScheduled(location){
    return !location?.requiresAppointment && location?.windows?.length && !this.isAwaitingResponse(location) && !this.isApptScheduled(location) && !location?.skipAppointment
  }

  isApptEmpty(location){
    return !location?.requiresAppointment && !location?.appointmentInfo?.from && !this.isAwaitingResponse(location) && !location?.skipAppointment
  }

  isAwaitingResponse(location){
    return location?.appointmentInfo?.status == 1 && !location?.skipAppointment
  }

  isNeedUpdateWindows(location){
    return !location?.requiresAppointment && !location?.windows?.length && !this.isApptScheduled(location) && !this.isAwaitingResponse(location) && !location?.skipAppointment
  }

  isNoScheduleNeeded(location) {
    return location?.skipAppointment;
  }

  private getCityState(addr: AddressUS) {
    return `${addr.city}, ${addr.state}`;
  }

  private getStreet(addr: AddressUS) {
    if (addr.street2) {
      return `${addr.street} ${addr.street2}`;
    } else {
      return addr.street;
    }
  }

  private getPick(item: ShipmentQueueShipment): ShipmentQueueDeliveryInfo {
    return item.deliveryInfos.filter(it => it.type == Const.TaskType.PICKUP)[0];
  }

  private getDrop(item: ShipmentQueueShipment): ShipmentQueueDeliveryInfo {
    return item.deliveryInfos.filter(it => it.type == Const.TaskType.DROPOFF)[0];
  }

  private updateDeliveryInfo(shipment: ShipmentQueueShipment, deliveryId: StringULID, data: FormDataUpdateTimeWindows | FormDataBookAppointment) {
    const url = Const.APIV2(`shipments/${shipment.id}/delivery-info/${deliveryId}`);
    return this.api.PUT(url, data);
  }

  private updateDeliveryWindow(shipment: ShipmentQueueShipment, delivery: ShipmentQueueDeliveryInfo) {
    let type = delivery.type == Const.TaskType.PICKUP ? 'Pickup' : 'Delivery';
    UpdateTimeWindows.openModal(this.modalHelper, {
      onSubmitError: err => this.showErr(err),
      onSubmitSucceeded: resp => this.onBtnRefresh(),
      nzTitle: `Update ${type} Time Windows for shipment ${this.showShipmentWarpId(shipment.warpId)}`,
      nzComponentParams: {
        timezone: delivery.addr?.metadata?.timeZoneStandard,
        reasonCodes: MasterData.getChangeDateTimeReasons(),
        model: {
          windows: delivery.windows,
          reasonCodeId: delivery.reasonCodeId
        },
        submit: (data: FormDataUpdateTimeWindows) => this.updateDeliveryInfo(shipment, delivery.id, data)
      }
    });
  }

  private updateAppt(shipment: ShipmentQueueShipment, delivery: ShipmentQueueDeliveryInfo) {
    let type = delivery.type == Const.TaskType.PICKUP ? 'Pickup' : 'Delivery';
    const appointmentInfo = delivery?.appointmentInfo || {};
    BookAppointment.openModal(this.modalHelper, {
      onSubmitError: err => this.showErr(err),
      onSubmitSucceeded: resp => this.onBtnRefresh(),
      nzTitle: `Update ${type} Appointment for shipment ${this.showShipmentWarpId(shipment.warpId)}`,
      nzComponentParams: {
        timezone: delivery.addr?.metadata?.timeZoneStandard,
        reasonCodes: MasterData.getChangeDateTimeReasons(),
        model: {
          appointmentInfo,
          reasonCodeId: delivery.reasonCodeId,
        },
        submit: (data: FormDataBookAppointment) => this.updateDeliveryInfo(shipment, delivery.id, data)
      },
    });
  }

  onBtnUpdatePickAppt(item: ShipmentQueueShipment) {
    this.updateAppt(item, this.getPick(item));
  }

  onBtnUpdateDropAppt(item: ShipmentQueueShipment) {
    this.updateAppt(item, this.getDrop(item));
  }

  onBtnUpdatePickWindow(item: ShipmentQueueShipment) {
    this.updateDeliveryWindow(item, this.getPick(item));
  }

  onBtnUpdateDropWindow(item: ShipmentQueueShipment) {
    this.updateDeliveryWindow(item, this.getDrop(item));
  }

  previousOrderId: string;
  currentOrderId: string;
  previousLocationId: string;
  currentLocationId: string

  handleBoxInfoStyle(orderId, locationId){
    const boxInfoSelectedClass = "box-info-selected"
    const locationSelectedClass = "location-item-selected"

    this.previousOrderId = this.currentOrderId
    this.currentOrderId = orderId

    this.previousLocationId = this.currentLocationId
    this.currentLocationId = locationId

    //xử lý style khi select order
    const preOrderElm = document.getElementById(this.previousOrderId)
    preOrderElm?.classList?.remove(boxInfoSelectedClass)
    const currentOrderElm = document.getElementById(this.currentOrderId)
    currentOrderElm?.classList?.add(boxInfoSelectedClass)
    //xử lý style khi select location của order
    const preLocationElm = document.getElementById(this.previousLocationId)
    preLocationElm?.classList?.remove(locationSelectedClass)
    const currentLocationElm = document.getElementById(this.currentLocationId)
    currentLocationElm?.classList?.add(locationSelectedClass)
  }

  viewScheduleAppointment(orderId, locationId){
   this.handleBoxInfoStyle(orderId, locationId)
  }

  displayWindows(window, location){
    const timezone = location?.addr?.metadata?.timeZoneStandard
    if (window && timezone){
      return DateUtil.displayTimeWindow(window, {
        timezone: timezone,
        formatDateOnly: 'MM/DD/YY',
        format: "MM/DD/YY h:mm A",
      })
    }
    return
  }

  public getDataMore() {
    if (!this.isVisible) return
    if (this.isLoading) return
    if (this.listData?.length == this.totalCount) return false;
    let apiUrl = this.getApiUrl();
    if (!apiUrl) {
      return;
    }
    let params = this.prepareParamGetList();
    params.skip = this.listData.length;
    let qs = new URLSearchParams(params).toString();
    if (apiUrl.indexOf('?') === -1) {
      apiUrl += '?';
    } else {
      apiUrl += '&';
    }
    apiUrl += qs;
    this.getDataByUrlLoadMore(this.api.buildUrl(apiUrl));
  }

  protected getDataByUrlLoadMore(url: string) {
    this.isLoading = true;
    this.subApi?.unsubscribe();
    this.subApi = this.api.GET(url).subscribe(
      resp => {
        this.paginationData.append(resp.data);
        this.onGetDataSucceeded(resp);
        this.isLoading = false
      }, err => {
        this.showErr(err);
        this.isLoading = false
      }
    );
  }

  getApptContactType(location){
    const contactType = location?.appointmentInfo?.contactType
    switch (contactType) {
      case Const.AppointmentContactType.phone:
        return {
          text: "Phone Appointment",
          icon: "assets/img/shipment-queue/phone-icon.svg"
        }
      case Const.AppointmentContactType.sms:
        return {
          text: "Text Appointment",
          icon: "assets/img/shipment-queue/text-icon.svg"
        }
      case Const.AppointmentContactType.email:
        return {
          text: "Email Appointment",
          icon: "assets/img/shipment-queue/email-icon.svg"
        }
      default:
        return {
          text: contactType ? `${contactType} Appointment` : 'N/A',
          icon: "assets/img/shipment-queue/i-icon.svg"
        }
    }
  }

  onGetDataSucceeded(resp: any): void {
    setTimeout(() => {
      if (this.currentOrderId && this.currentLocationId){
        this.viewScheduleAppointment(this.currentOrderId, this.currentLocationId)
      } else {
        const shipment = resp?.data?.list_data?.[0]
        const deliveryInfos = shipment?.deliveryInfos || []
        const location = deliveryInfos?.find(d => !d?.appointmentInfo?.from) //chỉ select location chưa update appointment
        if (shipment?.id && location?.id){
          this.viewScheduleAppointment(shipment?.id, location?.id)
        }
      }
    }, 200);
  }

  ngOnDestroy(): void {
    this.viewScheduleAppointment(undefined, undefined)
  }

  onRefresh(){
    this.getData()
  }

  noScheduleClick(itemId, locationId, checked) {
    let url = Const.APIURI_SHIPMENT_QUEUE();
    this.api.PUT(`${url}/${itemId}/skip-appointment/${locationId}`, {skipAppointment: checked}).subscribe(
      resp => {
        this.onRefresh();
      }, err => {
        this.showErr(err);
      }
    )
  }
}